import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { StriveApiResponse } from '@strive/api';

import { ShippingInformation } from '../../shipping-information';

export function SizingKitAddressConfirm({
  address,
  banner,
}: {
  address: StriveApiResponse<'setWearableOrder'>['address'] | null;
  banner?: React.ReactNode;
}) {
  const swiper = useSwiper();
  return (
    <Flex direction={'column'} h={'full'} pt={'env(safe-area-inset-top)'}>
      {banner}
      <Flex direction={'column'} px={6} mt={'10'} mb={'auto'}>
        <Text textStyle={'h4'}>Confirm your Address</Text>
        <Text textStyle={'copy'} color={'neutral.600'} mb={'10'}>
          Please review the shipping address. If everything looks good,
          we&apos;ll place the order!
        </Text>

        {address && (
          <ShippingInformation
            onEdit={() => swiper.slidePrev()}
            address={address}
          />
        )}
      </Flex>
      <Box px={6} mt={'auto'} mb={'calc(env(safe-area-inset-bottom) + 24px)'}>
        <Button
          variant={'primary'}
          w={'full'}
          onClick={() => {
            swiper.slideNext();
          }}
        >
          Confirm and Submit Order
        </Button>
      </Box>
    </Flex>
  );
}
