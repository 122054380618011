import Swiper from 'swiper';
import { useSwiper } from 'swiper/react';

import { queryClient } from '@strive/api';

import { StrivewareSetup } from '../wearable/striveware/setup/striveware-setup';
import { LaunchpadAuthorizeDataSync } from './launchpad-authorize-data-sync';
import { useUpdateLaunchpadChecklist } from './use-update-launchpad-checklist';

export type LaunchpadConnectWearableProps = {
  onClose: () => void;
  wearableProvider: 'striveware' | 'whoop';
  banner?: React.ReactNode;
};
export function LaunchpadConnectWearable({
  wearableProvider,
  onClose,
  banner,
}: LaunchpadConnectWearableProps) {
  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });
  const swiper = useSwiper() as Swiper | null; // Swiper is null if not in a swiper context

  const onBack = () => {
    if (swiper && swiper.slides.length > 1 && !swiper.isBeginning) {
      swiper.slidePrev();
    } else {
      onClose();
    }
  };

  return (
    <>
      {banner}
      {wearableProvider === 'striveware' ? (
        <StrivewareSetup
          onCancel={() => {
            onBack();
          }}
          onComplete={() =>
            updateChecklist.mutate({
              slug: 'sync_wearable',
              status: 'completed',
            })
          }
        />
      ) : (
        <LaunchpadAuthorizeDataSync
          onClose={() => {
            setTimeout(() => {
              updateChecklist
                .mutateAsync({
                  slug: 'setup_wearable',
                  status: 'completed',
                })
                .then(() => {
                  queryClient.invalidateQueries();
                });
            }, 1000); // Delay of 2 seconds
          }}
          onBack={onBack}
        />
      )}
    </>
  );
}
