import { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { AlertBox, SyncingIcon } from '@arena-labs/strive2-ui';
import { StrivewareContext, StrivewareSelector } from '@strive/wearable';

import { useWearableHub } from '../use-wearable-hub';

const ALERT_VARIANTS = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const DEBOUNCE_MS = 3000; // Match the toast debounce time

export function SyncAlerts() {
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  const { isOpen } = useWearableHub();
  const [showAlert, setShowAlert] = useState(false);
  const lastAlertTime = useRef(0);
  const lastDeviceState = useRef(deviceState);

  useEffect(() => {
    if (isOpen) return;

    const now = Date.now();

    if (now - lastAlertTime.current < DEBOUNCE_MS) {
      return;
    }

    if (deviceState === 'syncing' && deviceState !== lastDeviceState.current) {
      lastAlertTime.current = now;
      setShowAlert(true);
    } else if (
      deviceState !== 'syncing' &&
      deviceState !== lastDeviceState.current
    ) {
      lastAlertTime.current = now;
      setShowAlert(false);
    }

    lastDeviceState.current = deviceState;
  }, [deviceState, isOpen, showAlert]);

  if (isOpen || !showAlert) return null;

  return (
    <Box
      position="fixed"
      bottom="100px"
      left="50%"
      transform="translateX(-50%)"
      zIndex="toast"
      maxWidth="400px"
    >
      <Box as={motion.div} {...ALERT_VARIANTS}>
        <AlertBox
          variant="toast"
          status={'loading'}
          showIcon={SyncingIcon}
          title="Syncing your data. Keep the app open for a smooth and complete sync."
          iconAnimation="spin"
          textAlign="left"
          canClose={true}
        />
      </Box>
    </Box>
  );
}
