import { useState } from 'react';
import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useSwiper } from 'swiper/react';

import { AlertTriangle, InfoIcon, useGradient } from '@arena-labs/strive2-ui';
import { $API, Coach } from '@strive/api';
import { useDataStoreQuery } from '@strive/utils';

import { useSupportHub } from '../../../../components/help-center/use-support-hub';
import { useUpdateLaunchpadChecklist } from '../../use-update-launchpad-checklist';
import { selectedCoachStore } from '../selected-coach-store';

type FooterWithSwiperProps = {
  currentSlide: number;
  onClose: () => void;
  selectedCoach: Coach | null | undefined;
  onOpenScheduling: () => void;
  setHasConfirmedCoach?: (confirmed: boolean) => void;
  handleGoToNextSlide?: () => void;
};

export function FooterWithSwiper({
  currentSlide,
  onClose,
  selectedCoach,
  onOpenScheduling,
  setHasConfirmedCoach,
  handleGoToNextSlide,
}: FooterWithSwiperProps) {
  const [coachSelectionError, setCoachSelectionError] = useState(false);
  const [updateCheclistError, setUpdateChecklistError] = useState(false);
  const swiper = useSwiper();
  const gradient = useGradient('background');
  const isLastSlide = currentSlide === 2;
  const isConfirmSlide = currentSlide === 1;

  const [_, setSelectedCoachStore] = useDataStoreQuery(selectedCoachStore);

  const updateChecklist = useUpdateLaunchpadChecklist({
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      setUpdateChecklistError(true);
    },
  });

  const assignCoach = $API.useAssignCoach(
    {},
    {
      onSuccess: () => {
        if (selectedCoach) {
          setSelectedCoachStore.mutate(selectedCoach);

          // Set confirmed first so drawer knows we're confirmed
          setHasConfirmedCoach?.(true);

          // Make sure we wait a tiny bit before navigation to ensure everything is ready

          setTimeout(() => {
            if (swiper) {
              // Navigate to slide 2
              swiper.slideTo(2);
            } else if (handleGoToNextSlide) {
              handleGoToNextSlide();
            }
          }, 100); // Increased to 100ms for more reliability
        }
      },
      onError: () => {
        setCoachSelectionError(true);
      },
    },
  );
  const buttonText = {
    0: 'Continue',
    1: 'Confirm',
    2: 'Schedule Intro Call',
  }[currentSlide];

  const handleClose = (shouldScheduleCall: boolean) => {
    if (shouldScheduleCall) {
      // Ensure coach is in store before opening scheduling
      if (selectedCoach) {
        setSelectedCoachStore.mutate(selectedCoach);
      }
      onOpenScheduling();
      return;
    }
    // Only close and update checklist when not scheduling
    updateChecklist.mutate({
      slug: 'coach_selection_questionnaire',
      status: 'completed',
    });
  };

  const { onOpen } = useSupportHub();

  const handleConfirmCoach = () => {
    setUpdateChecklistError(false);
    assignCoach.mutate({
      username: selectedCoach?.username ?? '',
    });
  };

  return (
    <Flex
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      zIndex={10}
      p={4}
      direction="column"
      gap={2}
      bg={gradient}
      pb={'calc(24px + env(safe-area-inset-bottom))'}
    >
      {currentSlide === 1 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {coachSelectionError && (
            <Box
              px={3}
              py={2}
              mx={'auto'}
              mt={'auto'}
              transition={'all 0.35s ease'}
              w="full"
              bg={'#FDE6E6'}
              rounded={'card'}
              textAlign={'center'}
            >
              <Flex gap={2} align="flex-start" alignItems={'center'} pb={4}>
                <Icon as={AlertTriangle} boxSize={6} color="negative.400" />
                <Text
                  color="negative.400"
                  textStyle={'copy_small'}
                  textAlign={'left'}
                >
                  We were not able to assign your coach. Please try again.
                </Text>
              </Flex>
              <Flex justify={'center'} gap={2}>
                <Text textStyle={'copy_extra_small'}>
                  Still having trouble?
                </Text>
                <Button variant="link" onClick={() => onOpen()} size="sm">
                  <Text textStyle={'copy_extra_small'} color={'primary.500'}>
                    Contact Support
                  </Text>
                </Button>
              </Flex>
            </Box>
          )}
          <Flex align="center" gap={2} justify="center" mb={6}>
            <Icon as={InfoIcon} boxSize={4} color="neutral.600" />
            <Text textStyle="copy_small" color="neutral.600">
              No going back after making your selection.
            </Text>
          </Flex>
        </motion.div>
      )}
      {currentSlide === 2 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {updateCheclistError && (
            <Box
              px={3}
              py={2}
              mx={'auto'}
              mt={'auto'}
              transition={'all 0.35s ease'}
              w="full"
              bg={'#FDE6E6'}
              rounded={'card'}
              textAlign={'center'}
            >
              <Flex gap={2} align="flex-start" alignItems={'center'} pb={4}>
                <Icon as={AlertTriangle} boxSize={6} color="negative.400" />
                <Text
                  color="negative.400"
                  textStyle={'copy_small'}
                  textAlign={'left'}
                >
                  We were not able to update the checklist. Please try again.
                </Text>
              </Flex>
              <Flex justify={'center'} gap={2}>
                <Text textStyle={'copy_extra_small'}>
                  Still having trouble?
                </Text>
                <Button variant="link" onClick={() => onOpen()} size="sm">
                  <Text textStyle={'copy_extra_small'} color={'primary.500'}>
                    Contact Support
                  </Text>
                </Button>
              </Flex>
            </Box>
          )}
          <Button
            variant="link"
            color="primary.500"
            w="full"
            mb={6}
            onClick={() => handleClose(false)}
          >
            <Text textStyle="button" color="primary.500">
              Schedule Later
            </Text>
          </Button>
        </motion.div>
      )}
      <Button
        variant="primary"
        w="full"
        onClick={() => {
          if (isLastSlide) {
            handleClose(true);
          } else if (isConfirmSlide) {
            handleConfirmCoach();
          } else {
            if (handleGoToNextSlide) {
              handleGoToNextSlide();
            } else {
              swiper?.slideNext();
            }
          }
        }}
        isLoading={assignCoach.isLoading || updateChecklist.isLoading}
        bg={
          currentSlide === 0 && !selectedCoach ? 'neutral.500' : 'primary.800'
        }
        pointerEvents={currentSlide === 0 && !selectedCoach ? 'none' : 'auto'}
        opacity={currentSlide === 0 && !selectedCoach ? 0.5 : 1}
      >
        {buttonText}
      </Button>
    </Flex>
  );
}
