import { Flex, Text } from '@chakra-ui/react';

export function LaunchpadActionText({
  boldText,
  bodyText,
}: {
  boldText: string;
  bodyText: string;
}) {
  return (
    <Flex direction={'column'} gap={4}>
      <Text textStyle={'h4'} color={'neutral.800'}>
        {boldText}
      </Text>
      <Text textStyle={'copy'} color={'neutral.600'}>
        {bodyText}
      </Text>
    </Flex>
  );
}
