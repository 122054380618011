import { Flex, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { $API, Address } from '@strive/api';

import { AddressForm } from '../../address-form';

export function SizingKitOrderForm({
  banner,
  setDeliveryAddress,
  address,
}: {
  address: Address | null;
  banner?: React.ReactNode;
  setDeliveryAddress: (address: Address) => void;
}) {
  const swiper = useSwiper();
  const { data } = $API.useGetUserProfile();
  return (
    <Flex
      direction={'column'}
      maxH={'100vh'}
      h={'full'}
      pt={'env(safe-area-inset-top)'}
      position={'relative'}
    >
      {banner}
      <Flex direction={'column'} px={6} pt={12} overflowY={'scroll'}>
        <Text textStyle={'h4'}>Provide your shipping address </Text>
        <Text textStyle={'copy'} color={'neutral.600'} mb={'10'}>
          Your WHOOP band will soon be on its way!
        </Text>

        <AddressForm
          defaultValues={
            data
              ? {
                  full_name: `${data.first_name} ${data.last_name}`,
                  shipping_email: data.email,
                  address: address?.address,
                  city: address?.city,
                  apartment: address?.apartment,
                  state: address?.state,
                  zip_code: address?.zip_code,
                  country: address?.country,
                  company: address?.company,
                }
              : {}
          }
          onSuccess={(address: Address) => {
            address && setDeliveryAddress(address);
            swiper.slideNext();
          }}
        />
      </Flex>
    </Flex>
  );
}
