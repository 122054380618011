import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@sentry/nextjs';

import { AlertBox, AlertTriangle, useGradient } from '@arena-labs/strive2-ui';
import { $API, Address, addressSchema, queryClient } from '@strive/api';

export function AddressForm({
  onSuccess,
  defaultValues,
}: {
  onSuccess: (address: Address) => void;
  defaultValues?: Partial<Address>;
}) {
  const bgGradient = useGradient('background');
  const updateAddress = $API.useSetWearableOrder(
    {},
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries();
        response.address && onSuccess(response.address);
      },
      onError: (e) =>
        captureException(e, {
          data: 'Submit Wearable Order Address Failed, Address Form View',
        }),
    },
  );

  const formMethods = useForm<Address>({
    resolver: zodResolver(addressSchema),
    defaultValues: {
      country: 'United States',
      ...defaultValues,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  const onSubmit = async (data: Address) => {
    updateAddress.mutate({ address: data, size: 0, style: '' });
  };

  return (
    <chakra.form
      display={'flex'}
      sx={{
        label: {
          fontSize: '13px',
          color: 'neutral.600',
        },
        input: {
          textStyle: 'copy',
          py: '6',
          bg: 'neutral.50',
          color: 'neutral.800',
          border: '1px solid',
          borderColor: 'neutral.400',
          borderRadius: '4px',
          _focus: {
            borderColor: 'neutral.400',
          },
        },
      }}
      flexDirection={'column'}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Flex direction={'column'} gap={4} pb={'250px'}>
        <FormControl isInvalid={Boolean(errors.full_name)}>
          <FormLabel>Full Name</FormLabel>
          <Input {...register('full_name')} placeholder={'Tracy Jones'} />
          <FormErrorMessage>
            {errors.full_name?.message ? errors.full_name.message : null}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.shipping_email)}>
          <FormLabel>Shipping Email Address (for tracking updates)</FormLabel>
          <Input
            {...register('shipping_email')}
            placeholder={"'my.email@emails.com'"}
          />
          <FormErrorMessage>
            {errors.shipping_email?.message
              ? errors.shipping_email.message
              : null}
          </FormErrorMessage>
        </FormControl>

        <Box>
          <FormControl isInvalid={Boolean(errors.address)}>
            <FormLabel>Shipping Address</FormLabel>

            <Input {...register('address')} placeholder={"'123 Main Street'"} />
            <FormErrorMessage>
              {errors.address?.message ? errors.address.message : null}
            </FormErrorMessage>
          </FormControl>
          <FormControl>
            <Input
              my={3}
              {...register('apartment')}
              placeholder={"'Apartment 33'"}
            />
          </FormControl>
          <FormControl>
            <Input {...register('company')} placeholder={"'Health Co'"} />
          </FormControl>
        </Box>
        <FormControl isInvalid={Boolean(errors.city)}>
          <FormLabel>City</FormLabel>
          <Input {...register('city')} placeholder={"'Springfield'"} />
          <FormErrorMessage>
            {errors.city?.message ? errors.city.message : null}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.state)}>
          <FormLabel>State/Region</FormLabel>
          <Input
            {...register('state')}
            placeholder={defaultValues?.state ? undefined : "'Ohio'"}
          />
          <FormErrorMessage>
            {errors.state?.message ? errors.state.message : null}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.zip_code)}>
          <FormLabel>Postal Code</FormLabel>
          <Input
            {...register('zip_code')}
            placeholder={defaultValues?.zip_code ? undefined : "'99999'"}
          />
          <FormErrorMessage>
            {errors.zip_code?.message ? errors.zip_code.message : null}
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <Box
        bg={bgGradient}
        w="full"
        pt={6}
        px={6}
        position={'absolute'}
        bottom={0}
        left={0}
      >
        {updateAddress.isError ? (
          <AlertBox
            textAlign={'left'}
            mb={4}
            status="error"
            title={'Oops!'}
            description={
              'We could not update your address at this time. Please, try again!'
            }
            variant="toast"
            showIcon={AlertTriangle}
          />
        ) : null}
        <Button
          isLoading={updateAddress.isLoading}
          type={'submit'}
          variant={'primary'}
          w={'full'}
          mt={'auto'}
          mb={'calc(env(safe-area-inset-bottom) + 24px)'}
        >
          Save
        </Button>
      </Box>
    </chakra.form>
  );
}
