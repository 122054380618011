import { Box, BoxProps, Center, Image } from '@chakra-ui/react';

import { ZStack } from '@arena-labs/strive2-ui';

export const BandOverGradient = (props: BoxProps) => {
  return (
    <ZStack {...props}>
      <Box {...props}>
        <svg
          style={{ width: '100%', height: '100%' }}
          viewBox="0 0 265 271"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <g opacity="0.35" filter="url(#filter0_b_562_8489)">
            <path
              d="M212.753 38.6242C242.247 63.1476 264.305 96.3123 264.983 134.605C265.67 173.463 245.908 209.014 216.212 234.177C185.42 260.269 146.003 277.011 106.411 268.973C66.0186 260.773 34.6124 230.48 16.3793 193.607C-1.96158 156.516 -6.4405 113.377 10.8934 75.8072C28.4817 37.6859 63.6084 9.18982 104.982 1.56874C144.295 -5.67283 182.046 13.0918 212.753 38.6242Z"
              fill="url(#paint0_radial_562_8489)"
            />
          </g>
          <defs>
            <filter
              id="filter0_b_562_8489"
              x="-16"
              y="-16"
              width="297"
              height="303"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_562_8489"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_562_8489"
                result="shape"
              />
            </filter>
            <radialGradient
              id="paint0_radial_562_8489"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(132.5 135.5) rotate(90) scale(135.5 132.5)"
            >
              <stop stop-color="#6991BA" />
              <stop offset="1" stop-color="#C2D3E3" />
            </radialGradient>
          </defs>
        </svg>
      </Box>
      <Center w={'full'} h={'full'}>
        <Image
          src={'./images/whoop/whoop-band-2025.webp'}
          alt={'Whoop Band'}
          w={'175px'}
        />
      </Center>
    </ZStack>
  );
};
