import 'swiper/css';

import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Center,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion, useAnimate } from 'framer-motion';
import type { Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { pluralize, useGradient, ZStack } from '@arena-labs/strive2-ui';
import { $API } from '@strive/api';
import { useDataStoreQuery } from '@strive/utils';

import { CoachCard } from './components/coach-card';
import { CoachDetail } from './components/coach-detail';
import { FooterWithSwiper } from './components/footer';
import { HeaderWithSwiper } from './components/header';
import { selectedCoachStore } from './selected-coach-store';

type SelectCoachDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpenScheduling: () => void;
};

export function SelectCoachDrawer({
  isOpen,
  onClose,
  onOpenScheduling,
}: SelectCoachDrawerProps) {
  const gradient = useGradient('background');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedCoach, setSelectedCoach] = useState<string>();
  const [viewingCoachDetails, setViewingCoachDetails] = useState<string>();
  const { onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
  const [scope, animate] = useAnimate();
  const [coachImageLoaded, setCoachImageLoaded] = useState(false);
  const swiperRef = useRef<SwiperType>();

  const { data: coaches } = $API.useGetCoaches();
  const [storedCoach] = useDataStoreQuery(selectedCoachStore);

  const selectedCoachData = selectedCoach
    ? coaches?.find((coach) => coach.username === selectedCoach)
    : null;

  // Track whether user has confirmed their coach (reached slide 2)
  const [hasConfirmedCoach, setHasConfirmedCoach] = useState(false);

  // Check for stored coach when component mounts
  useEffect(() => {
    if (storedCoach && storedCoach.data) {
      setSelectedCoach(storedCoach.data.username);
      setHasConfirmedCoach(true);
    }
  }, [storedCoach]);

  // Ensure slide state is reset when drawer opens/closes
  useEffect(() => {
    if (isOpen) {
      // Make sure our currentSlide state matches what we're displaying
      setCurrentSlide(hasConfirmedCoach ? 2 : 0);
    }
  }, [isOpen, hasConfirmedCoach]);

  // Update confirmation state when reaching slide 2
  useEffect(() => {
    if (currentSlide === 2) {
      setHasConfirmedCoach(true);
    }
  }, [currentSlide, selectedCoach, hasConfirmedCoach]);

  const handleClose = () => {
    // Reset selection ONLY if on first slide
    if (currentSlide === 0) {
      setSelectedCoach(undefined);
      setCurrentSlide(0);
      setHasConfirmedCoach(false);
    } else {
      console.log('[COACH_DRAWER] 🔒 Keeping selection ');
    }
    onClose();
  };

  const handleShowDetails = (coachUsername: string) => {
    setViewingCoachDetails(coachUsername);
    onDetailOpen();
    animate(scope.current, { y: 0 }, { duration: 0.3, ease: 'easeOut' });
  };

  const handleGoToNextSlide = () => {
    if (swiperRef.current) {
      let targetSlide = currentSlide + 1;

      // Special case - if coach has been confirmed, always go to slide 2
      if (hasConfirmedCoach) {
        targetSlide = 2;
      }

      swiperRef.current.slideTo(targetSlide, 300); // Add 300ms animation for smoother transition
    } else {
      console.log('[COACH_DRAWER] ❌ No swiper ref');
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} placement="right" size="full">
      <DrawerOverlay bg={gradient} />
      <DrawerContent>
        <Flex
          direction="column"
          h="100vh"
          w="full"
          position="relative"
          overflow="hidden"
          pt={'env(safe-area-inset-top)'}
        >
          <Swiper
            style={{ width: '100%', height: '100%' }}
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.activeIndex);
            }}
            initialSlide={hasConfirmedCoach ? 2 : 0}
            allowTouchMove={false}
            key={`${isOpen ? 'open' : 'closed'}-${
              hasConfirmedCoach ? 'confirmed' : 'unconfirmed'
            }`}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;

              // Force sync the swiper position with our state
              const targetSlide = hasConfirmedCoach ? 2 : 0;
              if (swiper.activeIndex !== targetSlide) {
                // Use immediate navigation with no animation
                swiper.slideTo(targetSlide, 0);
                // Also update our state to match
                setCurrentSlide(targetSlide);
              }
            }}
          >
            {currentSlide < 2 && (
              <>
                <HeaderWithSwiper
                  onClose={handleClose}
                  currentSlide={currentSlide}
                />
              </>
            )}
            <SwiperSlide>
              <Flex direction="column" h="full" w="full" pt={20} px={4}>
                <Text textStyle="h5" mb={2}>
                  Pick a Performance Coach to start your personalized journey!
                </Text>
                <Text textStyle="copy_small" color="neutral.800" mb={6}>
                  Each of our coaches has their own style and expertise, select
                  the coach that resonates most with you!
                </Text>
                <Text
                  textStyle="copy_small"
                  color="neutral.800"
                  mb={6}
                  mx={'auto'}
                >
                  {coaches?.length}{' '}
                  {pluralize(coaches?.length ?? 0, 'coach', 'coaches')}{' '}
                  available
                </Text>
                <Box overflowY="auto" flex={1}>
                  <Flex direction="column" gap={4} pb={'150px'}>
                    {coaches?.map((coach) => (
                      <CoachCard
                        key={coach.username}
                        coach={coach}
                        isSelected={selectedCoach === coach.username}
                        onSelect={() => setSelectedCoach(coach.username)}
                        onShowDetails={() => handleShowDetails(coach.username)}
                      />
                    ))}
                  </Flex>
                </Box>
              </Flex>
            </SwiperSlide>
            <SwiperSlide>
              <Flex direction="column" h="full" w="full" px={4} pt={20}>
                <Text textStyle="h5" mb={6}>
                  Confirm your Performance Coach.
                </Text>
                {selectedCoach && selectedCoachData && (
                  <CoachCard
                    coach={selectedCoachData}
                    isSelected={true}
                    onSelect={() => {
                      /* Coach already selected */
                    }}
                    onShowDetails={() => handleShowDetails(selectedCoach)}
                  />
                )}
              </Flex>
            </SwiperSlide>
            <SwiperSlide>
              <Flex
                direction="column"
                h="full"
                w="full"
                overflow="hidden"
                px={4}
              >
                <ZStack mt={'auto'}>
                  <Image
                    src={'/images/launchpad/coach-detail-bg.jpg'}
                    alt="background"
                    width="full"
                    height="250px"
                    objectFit="cover"
                    rounded="card"
                    shadow={'4dp'}
                  />
                  <Box
                    w="full"
                    h={'full'}
                    rounded={'card'}
                    bg={'neutralAlpha.800'}
                  />
                  <Center w={'full'} h={'full'}>
                    <Box pt={6}>
                      {selectedCoachData?.headshot_url && (
                        <Box position="relative">
                          {!coachImageLoaded && (
                            <Box
                              width="144px"
                              height="144px"
                              rounded="full"
                              bg="neutral.200"
                              mx="auto"
                            />
                          )}
                          <Image
                            src={selectedCoachData?.headshot_url}
                            alt="coach image"
                            width={'144px'}
                            height={'144px'}
                            rounded="full"
                            bg={'white'}
                            mt={'0'}
                            border={'2px solid'}
                            borderColor={'neutralAlpha.900'}
                            shadow={'8dp'}
                            mx={'auto'}
                            opacity={coachImageLoaded ? 1 : 0}
                            transition="opacity 0.5s"
                            onLoad={() => setCoachImageLoaded(true)}
                            position={
                              coachImageLoaded ? 'relative' : 'absolute'
                            }
                            top={0}
                          />
                        </Box>
                      )}
                      <Text
                        textStyle="h1"
                        color="neutral.white"
                        pt={'3'}
                        textAlign="center"
                      >
                        {selectedCoachData
                          ? `${selectedCoachData.first_name}`
                          : ''}
                      </Text>
                    </Box>
                  </Center>
                </ZStack>

                <Text
                  textStyle="h5"
                  color="neutral.800"
                  textAlign="center"
                  mb={'auto'}
                  px={6}
                  mt={6}
                >
                  Great choice!{' '}
                  {selectedCoachData ? `${selectedCoachData.first_name}` : ''}{' '}
                  will be your Performance Coach for the next 6 weeks.
                </Text>
              </Flex>
            </SwiperSlide>
          </Swiper>

          <FooterWithSwiper
            currentSlide={currentSlide}
            onClose={onClose}
            selectedCoach={selectedCoachData}
            onOpenScheduling={onOpenScheduling}
            setHasConfirmedCoach={setHasConfirmedCoach}
            handleGoToNextSlide={handleGoToNextSlide}
          />

          {/* Detail Panel */}
          <motion.div
            ref={scope}
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: 'auto',
              background: 'white',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              y: '100%',
              zIndex: 20,
              overflow: 'auto',
            }}
            initial={{ y: '100%' }}
          >
            <CoachDetail
              coach={coaches?.find(
                (coach) => coach.username === viewingCoachDetails,
              )}
              onClose={() => {
                onDetailClose();
                animate(
                  scope.current,
                  { y: '100%' },
                  { duration: 0.3, ease: 'easeOut' },
                );
              }}
            />
          </motion.div>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
}
