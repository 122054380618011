import { useEffect, useRef, useState } from 'react';
import {
  Center,
  chakra,
  Flex,
  HStack,
  Icon,
  Image,
  keyframes,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { useAnalytics } from '@arena-labs/analytics';
import {
  AlertTriangle,
  CheckCircleIcon,
  SyncingIcon,
  SyncWarning,
  toast,
  TriangleAlert,
  useAppStateChange,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $API, useUserProfile, Wearable } from '@strive/api';
import {
  IfWearable,
  StrivewareContext,
  StrivewareSelector,
  useStrivewareBattery,
} from '@strive/wearable';

import { StriveRing } from './striveware/striveware-device-details';
import { SyncAlerts } from './striveware/sync-alerts';
import { useWearableHub } from './use-wearable-hub';

// Define the spinning animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export function WearableButton() {
  const { onOpen } = useWearableHub();
  const analytics = useAnalytics();

  // Create a motion-enabled button with Chakra styling
  const MotionButton = chakra(motion.button);

  return (
    <IfWearable>
      {({ mode, wearable }) => {
        return (
          <MotionButton
            aria-label={'My Wearable'}
            whileTap={{
              boxShadow: 'none',
              scale: 0.85,
              y: '3px',
              transition: { duration: 0.1 },
            }}
            _hover={{
              textDecoration: 'none',
            }}
            _focusVisible={{
              outline: 'none',
              boxShadow: 'none',
              border: 'none',
            }}
            _focus={{
              outline: 'none',
              boxShadow: 'none',
              border: 'none',
            }}
            _active={{
              transform: 'scale(0.90)',
              bg: 'information.200',
              outline: 'none',
              boxShadow: 'none',
            }}
            sx={{
              WebkitTapHighlightColor: 'transparent',
              tapHighlightColor: 'transparent',
              outline: 'none',
              border: 'none',
            }}
            onClick={() => {
              analytics.user('click', {
                target: 'wearable-button',
                wearable: mode,
              });
              onOpen();
            }}
          >
            <Center h={'full'}>
              {mode === 'striveware' ? (
                <StrivewareButton />
              ) : (
                <WhoopButton wearable={wearable} />
              )}
            </Center>
          </MotionButton>
        );
      }}
    </IfWearable>
  );
}

function StrivewareButton() {
  const deviceState = StrivewareContext.useSelector(
    StrivewareSelector.deviceState,
  );
  const batteryQuery = useStrivewareBattery();
  const [showSuccessIcon, setShowSuccessIcon] = useState(false);
  const { isOpen } = useWearableHub();
  const lastDeviceState = useRef(deviceState);
  const [lastToastTime, setLastToastTime] = useState(0);
  const [_, setSyncStartTime] = useState<number | null>(null);

  // Handle success icon display
  useEffect(() => {
    if (deviceState === 'synced') {
      setShowSuccessIcon(true);
      const timer = setTimeout(() => {
        setShowSuccessIcon(false);
      }, 5000); // 5 seconds
      return () => clearTimeout(timer);
    }
  }, [deviceState]);

  // Track sync start time
  useEffect(() => {
    if (deviceState === 'syncing') {
      setSyncStartTime(Date.now());
    } else {
      setSyncStartTime(null);
    }
  }, [deviceState]);

  // Handle toast notifications
  useEffect(() => {
    if (isOpen) return;

    const now = Date.now();

    if (deviceState === 'synced' && lastDeviceState.current === 'syncing') {
      setLastToastTime(now);
      toast({
        status: 'success',
        title: 'All Set! Your data is up to date.',
        duration: 3000,
        icon: <Icon as={CheckCircleIcon} color="positive.300" boxSize={6} />,
        variant: 'toast',
        id: 'sync-success',
      });
    } else if (
      (deviceState === 'sync-failed' &&
        deviceState !== lastDeviceState.current) ||
      (deviceState === 'disconnected' && lastDeviceState.current === 'syncing')
    ) {
      setLastToastTime(now);
      toast({
        status: 'error',
        title: 'We were unable to sync your data. Please try again.',
        duration: 3000,
        icon: <Icon as={AlertTriangle} color="warning.400" boxSize={6} />,
        variant: 'toast',
        id: 'sync-error',
      });
    }

    lastDeviceState.current = deviceState;
  }, [deviceState, isOpen, lastToastTime]);

  // Determine the sync state based on deviceState
  const getSyncState = () => {
    if (!deviceState || deviceState === 'disconnected') {
      return 'no-connection';
    } else if (deviceState === 'syncing') {
      return 'syncing';
    } else if (deviceState === 'synced' && showSuccessIcon) {
      return 'success';
    } else if (deviceState === 'sync-failed') {
      return 'failed';
    } else if (deviceState === 'connected' || deviceState === 'synced') {
      return 'connected'; // Connected but no icon needed
    }
    return 'no-connection'; // Default state
  };

  const syncState = getSyncState();

  return (
    <>
      <SyncAlerts />
      <Flex gap={2} alignItems={'center'}>
        {syncState === 'no-connection' && (
          <Icon as={AlertTriangle} color="negative.400" boxSize={5} />
        )}

        {syncState === 'syncing' && (
          <Icon
            as={SyncingIcon}
            color="neutral.400"
            boxSize={5}
            animation={`${spin} 2s linear infinite`}
          />
        )}
        {syncState === 'success' && (
          <Icon as={CheckCircleIcon} color="positive.300" boxSize={5} />
        )}

        {syncState === 'failed' && (
          <Icon as={SyncWarning} color="warning.400" boxSize={5} />
        )}
        <StriveRing deviceState={deviceState} batteryQuery={batteryQuery} />
      </Flex>
    </>
  );
}

function WhoopButton({ wearable }: { wearable: Wearable }) {
  const baselineQuery = $API.useGetProgressData();
  const { invalidate } = useUserProfile();
  useAppStateChange((isActive) => {
    if (isActive) invalidate();
  });

  return (
    <HStack gap={0}>
      {wearable.status !== 'valid' ? (
        <Center w={'full'} pl={1}>
          <Icon
            as={TriangleAlert}
            color={'negative.400'}
            w={'20px'}
            h={'auto'}
          />
        </Center>
      ) : (
        <WaitForQuery query={baselineQuery} loading={null} error={null}>
          {(baseline) => (
            <>
              {!baseline.results.find(
                (result) => result.date === format(new Date(), 'yyyy-MM-dd'),
              )?.hrv && (
                <Center w={'full'}>
                  <Icon
                    as={SyncWarning}
                    color={'warning.400'}
                    boxSize={6}
                    h={'auto'}
                  />
                </Center>
              )}
            </>
          )}
        </WaitForQuery>
      )}
      <Image
        src={'./images/whoop/whoop-band-2025.webp'}
        w={6}
        h={'auto'}
        alt=""
        mr={1}
        ml={1}
      />
    </HStack>
  );
}
