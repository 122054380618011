import {
  Button,
  Flex,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { RadioButton } from '@arena-labs/strive2-ui';

export function DeviceOwnershipCheck({
  wearable,
  setSelection,
  isValid,
  banner,
}: {
  banner?: React.ReactNode;
  isValid: boolean;
  wearable: string;
  setSelection: (selection: 'Connect OAuth' | 'Order Wearable') => void;
  onClose: () => void;
}) {
  const swiper = useSwiper();

  const { getRadioProps } = useRadioGroup({
    name: 'device_ownership',
    onChange: (deviceOwned: 'Connect OAuth' | 'Order Wearable') =>
      setSelection(deviceOwned),
  });

  return (
    <Flex
      direction={'column'}
      w={'100vw'}
      h="full"
      pt={'env(safe-area-inset-top)'}
    >
      {banner}
      <Flex direction={'column'} h={'full'} px={6} mt={'40px'} gap={10}>
        <Text textStyle={'h4'}>
          Do you currently own{' '}
          {wearable === 'oura'
            ? 'an Oura Ring'
            : wearable === 'whoop'
            ? 'a WHOOP band'
            : wearable === 'striveware'
            ? 'a StriveWare ring'
            : 'a device'}
          ?
        </Text>
        <Flex direction={'column'} gap={3}>
          <RadioButton
            variant={'secondary'}
            w={'full'}
            {...(getRadioProps({
              value: 'Connect OAuth',
            }) as RadioProps)}
          >
            Yes, I do
          </RadioButton>
          <RadioButton
            variant={'secondary'}
            {...(getRadioProps({
              value: `Order Wearable`,
            }) as RadioProps)}
            w={'full'}
          >
            No, I don&apos;t
          </RadioButton>
        </Flex>
        <Button
          variant={'primary'}
          w={'full'}
          mt={'auto'}
          mb={'calc(env(safe-area-inset-bottom) + 24px)'}
          onClick={() => {
            swiper.slideNext();
          }}
          disabled={!isValid}
        >
          Continue
        </Button>
      </Flex>
    </Flex>
  );
}
