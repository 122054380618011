import { Box, Button, Center, Flex, Text } from '@chakra-ui/react';

import { ToolKitTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  isMediaPlayedThisSession,
  isPillarAudio,
  isPillarVideo,
  PillarPractice,
  PlayableAudio,
  PlayableMedia,
  PlayableVideo,
} from '@arena-labs/shared-models';
import { HomeScreenResponse } from '@arena-labs/strive2-coaching';
import { queryClient } from '@strive/api';
import { MediaQueue, MediaQueueEndPromptProps } from '@strive/av';

import { useLogPractice } from '../practice/use-log-practice';

export type SessionMediaProps = {
  learningSession: NonNullable<HomeScreenResponse['learning_session']>;
  onClose: () => void;
};

export function SessionMedia({ learningSession, onClose }: SessionMediaProps) {
  const { media_items, practices } = learningSession;

  // Filter to only video and audio items
  const filteredMediaItems = media_items.filter(
    (item) => item.type === 'video' || item.type === 'audio',
  ) as Array<PlayableVideo | PlayableAudio>;

  const unplayedIndex = filteredMediaItems.findIndex(
    (item) => !isMediaPlayedThisSession(item),
  );

  const practiceMap = new Map(
    practices.map((practice) => [practice.coach_me.slug, practice]),
  );

  // We could consider filtering out any watched videos here
  // but that might cause issues with the Swiper internal state
  // regarding which slide is active after you watch a video.
  // We need to do a bit of testing to see if this is a problem.
  return (
    <Box
      h="full"
      mx="-6"
      sx={{
        '.chakra-fade > *:first-child': {
          bg: 'neutral.white',
        },
      }}
    >
      <MediaQueue
        mode="strict"
        items={filteredMediaItems}
        initialIndex={unplayedIndex}
        swipeToClose={true}
        boxProps={{ px: '6' }}
        videoProps={{ objectPosition: 'top center' }}
        endPrompt={(props) => (
          <SessionMediaEndPrompt {...props} practiceMap={practiceMap} />
        )}
        onClose={onClose}
      />
    </Box>
  );
}

type SessionMediaEndPromptProps = MediaQueueEndPromptProps & {
  practiceMap: Map<string, PillarPractice>;
};
function SessionMediaEndPrompt({
  media,
  practiceMap,
  ...props
}: SessionMediaEndPromptProps) {
  const getMediaTitle = (item?: PlayableMedia) => {
    return item?.title;
  };

  const practice =
    isPillarAudio(media) || isPillarVideo(media)
      ? practiceMap.get(media.slug)
      : null;

  const analytics = useAnalytics();
  const updatePractice = useLogPractice({ retry: 3 });

  const onMarkPracticeComplete = async () => {
    if (!practice) return;

    await updatePractice.mutateAsync({ practice: practice.slug });
    analytics.logEvent(ToolKitTrackingEvent.ToolCompleted, {
      practiceSlug: practice.slug,
      practiceName: practice.title,
    });
    queryClient.invalidateQueries();
    props.onContinue();
  };

  if ((isPillarAudio(media) || isPillarVideo(media)) && practice) {
    return (
      <Flex direction="column" gap="4" width="full">
        <Text textStyle={'h5'} textAlign="center">
          Did you try {practice?.short_title}?
        </Text>
        {props.onReplay && (
          <Button variant="secondary" onClick={props.onReplay}>
            Guide Me Again
          </Button>
        )}
        <Button
          variant="secondary"
          onClick={props.onContinue}
          isLoading={props.continuePending}
        >
          Will Try Later
        </Button>
        <Button
          variant="primary"
          onClick={onMarkPracticeComplete}
          isLoading={updatePractice.isLoading || props.continuePending}
        >
          YES!
        </Button>
      </Flex>
    );
  } else {
    return (
      <Center>
        <Flex gap="4" direction="column" width="full">
          {props.nextMedia ? (
            <Flex gap="1" direction="column">
              <Text
                textStyle={'copy_small'}
                textAlign="center"
                color="gray.250"
              >
                NEXT UP:
              </Text>
              <Text
                textStyle={'copy_bold'}
                textAlign="center"
                letterSpacing="0.05em"
              >
                {getMediaTitle(props.nextMedia)}
              </Text>
            </Flex>
          ) : null}

          {props.onReplay && (
            <Button variant="secondary" onClick={props.onReplay}>
              Replay Current Lesson
            </Button>
          )}

          <Button
            variant="primary"
            isLoading={props.continuePending}
            onClick={props.onContinue}
          >
            CONTINUE
          </Button>
        </Flex>
      </Center>
    );
  }
}
