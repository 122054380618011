import { useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  Icon,
  Text,
} from '@chakra-ui/react';

import {
  AlertTriangle,
  CloseIcon,
  useGradient,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { useDataStoreQuery } from '@strive/utils';

import { useSupportHub } from '../../help-center/use-support-hub';
import { selectedCoachStore } from '../select-coach/selected-coach-store';
import { useUpdateLaunchpadChecklist } from '../use-update-launchpad-checklist';

// Add type declaration for Calendly config
declare global {
  interface Window {
    calendlyConfig?: {
      prefetch: boolean;
    };
  }
}

if (typeof window !== 'undefined') {
  window.calendlyConfig = {
    prefetch: true,
  };
}

type ScheduleCallModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseCoachSelection: () => void;
};

export function ScheduleCallModal({
  isOpen,
  onClose,
  onCloseCoachSelection,
}: ScheduleCallModalProps) {
  const gradient = useGradient('background');
  const { onOpen } = useSupportHub();
  const [updateChecklistError, setUpdateChecklistError] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [selectedCoachQuery] = useDataStoreQuery(selectedCoachStore);

  const updateChecklist = useUpdateLaunchpadChecklist({
    onError: (error) => {
      setUpdateChecklistError(true);
    },
  });

  useEffect(() => {
    const handleScheduled = (e: any) => {
      if (e.data.event === 'calendly.event_scheduled') {
        setIsScheduled(true);
      }
    };

    window.addEventListener('message', handleScheduled);

    return () => window.removeEventListener('message', handleScheduled);
  }, []);

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="full">
      <DrawerOverlay bg={gradient} />
      <DrawerContent>
        <Flex
          bg={gradient}
          direction="column"
          h="100vh"
          w="full"
          position="relative"
          overflow="hidden"
          pt={'env(safe-area-inset-top)'}
          pb={'calc(env(safe-area-inset-bottom) + 24px)'}
        >
          {!isScheduled && (
            <Grid templateColumns="50px 1fr 50px" alignItems="center" p={4}>
              <Text textStyle="copy" textAlign="center" gridColumn="2">
                Schedule an Intro Call
              </Text>
              <Button
                onClick={() => {
                  setUpdateChecklistError(false);

                  onCloseCoachSelection();

                  onClose();
                  updateChecklist
                    .mutateAsync({
                      slug: 'coach_selection_questionnaire',
                      status: 'completed',
                    })
                    .then(() => {
                      if (isScheduled) {
                        updateChecklist.mutateAsync({
                          slug: 'schedule_coaching_call',
                          status: 'completed',
                        });
                      }
                    });
                }}
                backgroundColor="transparent"
                p={2}
                gridColumn="3"
                justifySelf="end"
                isLoading={updateChecklist.isLoading}
              >
                <Icon as={CloseIcon} boxSize="7" />
              </Button>
            </Grid>
          )}
          {/* Content */}
          <Flex direction="column" flex={1} px={0} gap={4}>
            <WaitForQuery query={selectedCoachQuery}>
              {(selectedCoach) => {
                return (
                  <>
                    {isScheduled && (
                      <Text
                        px={6}
                        as="h3"
                        textStyle="h3"
                        mb={4}
                        mt={'calc(48px + env(safe-area-inset-top))'}
                      >
                        Intro call with {selectedCoach?.first_name} is
                        confirmed.
                      </Text>
                    )}
                    <Box
                      w={'100%'}
                      overflow={'hidden'}
                      bg={'transparent'}
                      h={'100%'}
                    >
                      <InlineWidget
                        url={selectedCoach?.calendly_link ?? ''}
                        styles={{
                          height: '100%',
                          width: '100%',
                          minWidth: '320px',
                          userSelect: 'auto',
                          WebkitUserSelect: 'auto',
                          backgroundColor: 'transparent',
                        }}
                        pageSettings={{
                          backgroundColor: 'transparent',
                          hideEventTypeDetails: false,
                          hideLandingPageDetails: false,
                          primaryColor: '00a2ff',
                          textColor: '4d5055',
                        }}
                      />
                    </Box>
                    {updateChecklistError && (
                      <Box
                        px={3}
                        py={2}
                        mx={'auto'}
                        mt={'auto'}
                        transition={'all 0.35s ease'}
                        w="full"
                        bg={'#FDE6E6'}
                        rounded={'card'}
                        textAlign={'center'}
                      >
                        <Flex
                          gap={2}
                          align="flex-start"
                          alignItems={'center'}
                          pb={4}
                        >
                          <Icon
                            as={AlertTriangle}
                            boxSize={6}
                            color="negative.400"
                          />
                          <Text
                            color="negative.400"
                            textStyle={'copy_small'}
                            textAlign={'left'}
                          >
                            We were not able to update the checklist. Please try
                            again.
                          </Text>
                        </Flex>
                        <Flex justify={'center'} gap={2}>
                          <Text textStyle={'copy_extra_small'}>
                            Still having trouble?
                          </Text>
                          <Button
                            variant="link"
                            onClick={() => onOpen()}
                            size="sm"
                          >
                            <Text
                              textStyle={'copy_extra_small'}
                              color={'primary.500'}
                            >
                              Contact Support
                            </Text>
                          </Button>
                        </Flex>
                      </Box>
                    )}
                    {isScheduled && (
                      <Box px={6}>
                        <Button
                          variant="primary"
                          size="lg"
                          width="full"
                          onClick={() => {
                            setUpdateChecklistError(false);

                            onCloseCoachSelection();

                            onClose();
                            updateChecklist
                              .mutateAsync({
                                slug: 'coach_selection_questionnaire',
                                status: 'completed',
                              })
                              .then(() => {
                                if (isScheduled) {
                                  updateChecklist.mutateAsync({
                                    slug: 'schedule_coaching_call',
                                    status: 'completed',
                                  });
                                }
                              });
                          }}
                          mt="auto"
                          isLoading={updateChecklist.isLoading}
                        >
                          Done
                        </Button>
                      </Box>
                    )}
                  </>
                );
              }}
            </WaitForQuery>
          </Flex>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
}
