import { Button, Image, Text, VStack } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { BandOverGradient } from '../../../../components/wearable/whoop/band-over-gradient';
import { LaunchpadActionView } from '../../launchpad-action-view';

export function SizingKitIntro({
  wearableProvider,
  onClose,
  banner,
}: {
  wearableProvider: 'striveware' | 'whoop';
  onClose?: () => void;
  banner?: React.ReactNode;
}) {
  const swiper = useSwiper();

  const strivewareHero = (
    <VStack spacing={8}>
      <Text textStyle={'h1'} color={'neutral.white'}>
        StriveWare
      </Text>
      <Image
        src={'./images/striveware/striveware-sizing-box.webp'}
        alt={'Oura Ring'}
        w={'265px'}
      />
    </VStack>
  );

  const whoopHero = (
    <VStack spacing={8}>
      <BandOverGradient />
    </VStack>
  );

  const strivewareTextEntries = (
    <VStack spacing={4}>
      <Text textStyle={'h2'}>Request a Sizing Kit Now</Text>
      <Text textStyle={'copy'} color={'neutral.400'}>
        Discover the power of Strive&apos;s cutting-edge sensor, unlocking
        valuable physiological data. Simply share your shipping address to
        receive a sizing kit and embark on your journey to enhanced well-being.
      </Text>
    </VStack>
  );

  const whoopTextEntries = (
    <VStack spacing={4}>
      <Text textStyle={'h4'} color={'neutral.800'}>
        Claim your WHOOP today!
      </Text>
      <Text textStyle={'copy'} color={'neutral.600'}>
        Submit your order now, and your WHOOP will be on its way in no time!
      </Text>
    </VStack>
  );

  return (
    <LaunchpadActionView
      title={
        wearableProvider === 'striveware' ? 'StriveWare' : 'Submit WHOOP Order'
      }
      onBack={() => {
        wearableProvider === 'striveware'
          ? onClose && onClose()
          : swiper && swiper.slidePrev();
      }}
      hero={wearableProvider === 'striveware' ? strivewareHero : whoopHero}
      textBox={
        wearableProvider === 'striveware'
          ? strivewareTextEntries
          : whoopTextEntries
      }
      footer={
        <Button
          variant={'primary'}
          onClick={() => swiper.slideNext()}
          w={'full'}
        >
          Continue
        </Button>
      }
    />
  );
}
