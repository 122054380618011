import { useState } from 'react';
import NextImage from 'next/image';
import { Box, Button, Flex, HStack, Icon, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import {
  CheckCircleFilledIcon,
  CircleIcon,
  FemaleIcon,
  MaleIcon,
} from '@arena-labs/strive2-ui';
import { Coach } from '@strive/api';

type CoachCardProps = {
  coach: Coach;
  isSelected?: boolean;
  onSelect?: () => void;
  onShowDetails: () => void;
};

export function CoachCard(props: CoachCardProps) {
  const { coach, onShowDetails, isSelected = false, onSelect } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Box as="div" w="full" onClick={onSelect}>
      <Box
        borderColor={isSelected ? 'primary.800' : 'neutralAlpha.100'}
        overflow="hidden"
        borderRadius="20px"
        borderWidth={isSelected ? '3px' : '1px'}
      >
        <Flex
          direction="column"
          bg="white"
          cursor="pointer"
          position="relative"
          transition="all 0.2s"
          _hover={{
            borderColor: 'primary.800',
          }}
        >
          <Box
            h={'8px'}
            w={'full'}
            bg={isSelected ? 'neutralAlpha.900' : 'neutralAlpha.400'}
          ></Box>
          {/* Icon - both states */}
          <motion.div
            initial={{ scale: isSelected ? 0 : 1 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'absolute',
              top: '12px',
              right: '12px',
              paddingTop: '10px',
              paddingRight: '4px',
            }}
          >
            <Icon
              as={isSelected ? CheckCircleFilledIcon : CircleIcon}
              color={isSelected ? 'primary.800' : 'neutralAlpha.200'}
              boxSize={'32px'}
            />
          </motion.div>

          {/* Coach Info */}
          <Flex gap={4} align="center" px={4} pt={3}>
            <Box position="relative" width="48px" height="48px">
              <Box
                position="absolute"
                width="48px"
                height="48px"
                rounded="full"
                bg="gray.100"
              />
              <Box
                style={{ opacity: imageLoaded ? 1 : 0 }}
                transition="opacity 0.5s"
                position="relative"
                width="48px"
                height="48px"
              >
                <NextImage
                  src={coach.headshot_url ?? '/images/default-avatar.jpg'}
                  alt={`${coach.first_name} ${coach.last_name}`}
                  fill
                  sizes="48px"
                  style={{
                    borderRadius: '100%',
                    objectFit: 'cover',
                  }}
                  onLoadingComplete={() => setImageLoaded(true)}
                />
              </Box>
            </Box>
            <HStack gap={3}>
              <Text textStyle="h5">{`${coach.first_name} `} </Text>
              <Icon
                as={
                  coach.gender.includes('f') || coach.gender.includes('F')
                    ? FemaleIcon
                    : MaleIcon
                }
                ml={'3'}
                boxSize={
                  coach.gender.includes('f') || coach.gender.includes('F')
                    ? 5
                    : 4
                }
                color={
                  coach.gender.includes('f') || coach.gender.includes('F')
                    ? '#F1786B'
                    : '#5AC8AE'
                }
              />
            </HStack>
          </Flex>

          <Text textStyle="copy_small" color="neutral.800" px={4} my={2}>
            {coach.short_bio ?? ''}
          </Text>

          <Button
            variant="link"
            w="full"
            mt={2}
            mb={2}
            onClick={(e) => {
              e.stopPropagation(); // Prevent selection
              onShowDetails();
            }}
          >
            <Text textStyle="copy_extra_small" color="primary.500">
              More about {coach.first_name}
            </Text>
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
