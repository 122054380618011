import { useState } from 'react';
import { Flex, Modal, ModalBody, ModalContent } from '@chakra-ui/react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useGradient, useModalDisclosure } from '@arena-labs/strive2-ui';
import { $API, Address } from '@strive/api';

import { LaunchpadConnectWearable } from '../launchpad-connect-wearable';
import { DeviceOwnershipCheck } from './device-ownership-check';
import { SizingKitAddressConfirm } from './order-ring-sizing-kit/sizing-kit-address-confirm';
import { SizingKitIntro } from './order-ring-sizing-kit/sizing-kit-intro';
import { SizingKitOrderForm } from './order-ring-sizing-kit/sizing-kit-order-form';
import { SizingKitOrderRecieved } from './order-ring-sizing-kit/sizing-kit-order-recieved';
import { OrderBanner } from './order-ring-sizing-kit/whoop-order-banner';

WearableQuestionnaire.useDisclosure = function () {
  return useModalDisclosure('Wearable Questionnaire');
};

export function WearableQuestionnaire({
  wearableProvider,
  isOpen,
  onClose,
}: {
  wearableProvider: 'striveware' | 'whoop';
  isOpen: boolean;
  onClose: () => void;
}) {
  const gradient = useGradient('background');
  const [view, setView] = useState<'Connect OAuth' | 'Order Wearable' | null>(
    wearableProvider === 'striveware' ? 'Order Wearable' : null,
  );

  const [address, setAddress] = useState<Address | null>(null);
  const { data: wearableOrder } = $API.useGetWearableOrder();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={gradient}>
        <ModalBody m={0} p={0} bgGradient={gradient}>
          <Flex h={'full'} maxH={'100vh'} w={'full'}>
            <Swiper allowTouchMove={false} style={{ width: '100%' }}>
              <SwiperSlide>
                <DeviceOwnershipCheck
                  banner={
                    <OrderBanner
                      onClose={onClose}
                      title={
                        wearableProvider === 'whoop'
                          ? 'Submit WHOOP Order'
                          : 'Device Order'
                      }
                    />
                  }
                  onClose={onClose}
                  isValid={Boolean(view)}
                  setSelection={(
                    selection: 'Connect OAuth' | 'Order Wearable',
                  ) => setView(selection)}
                  wearable={wearableProvider}
                />
              </SwiperSlide>
              {view == 'Connect OAuth' ? (
                <SwiperSlide>
                  <LaunchpadConnectWearable
                    wearableProvider={wearableProvider}
                    onClose={onClose}
                  />
                </SwiperSlide>
              ) : view == 'Order Wearable' ? (
                <>
                  <SwiperSlide key={'1'}>
                    <SizingKitIntro
                      onClose={
                        wearableProvider === 'striveware' ? onClose : undefined
                      }
                      wearableProvider={wearableProvider}
                    />
                  </SwiperSlide>
                  <SwiperSlide key={'2'}>
                    <SizingKitOrderForm
                      banner={
                        <OrderBanner
                          onClose={onClose}
                          title={
                            wearableProvider === 'whoop'
                              ? 'Submit WHOOP Order'
                              : 'Device Order'
                          }
                        />
                      }
                      setDeliveryAddress={(address: Address) =>
                        setAddress(address)
                      }
                      address={wearableOrder?.address ?? address ?? null}
                    />
                  </SwiperSlide>
                  <SwiperSlide key={'3'}>
                    <SizingKitAddressConfirm
                      banner={
                        <OrderBanner
                          onClose={onClose}
                          title={
                            wearableProvider === 'whoop'
                              ? 'Submit WHOOP Order'
                              : 'Device Order'
                          }
                        />
                      }
                      address={address}
                    />
                  </SwiperSlide>
                  <SwiperSlide key={'4'}>
                    <SizingKitOrderRecieved onProceed={() => onClose()} />
                  </SwiperSlide>
                </>
              ) : null}
            </Swiper>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
