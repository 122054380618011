import { Button, Center, Image } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { AlertBox, AlertTriangle, ZStack } from '@arena-labs/strive2-ui';

import { LaunchpadActionText } from '../../launchpad-action-text';
import { LaunchpadActionView } from '../../launchpad-action-view';
import { useUpdateLaunchpadChecklist } from '../../use-update-launchpad-checklist';

export function SizingKitOrderRecieved({
  onProceed,
}: {
  onProceed: () => void;
}) {
  const swiper = useSwiper();

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onProceed });

  return (
    <LaunchpadActionView
      title={'Submit WHOOP Order'}
      onBack={() => swiper.slidePrev()}
      hero={
        <ZStack mx={-6}>
          <Image
            src={'./images/celebration.webp'}
            alt={'Whoop Band'}
            w={'full'}
            mx={'auto'}
            opacity={0.25}
            zIndex={-1}
            minH={'220px'}
          />
          <Center h={'full'} w={'full'}>
            <Image
              src={'./images/whoop/whoop-band-2025.webp'}
              alt={'Whoop Band'}
              w={'144px'}
              mx={'auto'}
              opacity={1}
            />
          </Center>
        </ZStack>
      }
      textBox={
        <LaunchpadActionText
          boldText={'Order confirmed!'}
          bodyText={
            'Get Excited! Your WHOOP is on its way. Anticipate delivery in 5-7 days. Get ready for a personalized journey with Strive!'
          }
        />
      }
      footer={
        <>
          {updateChecklist.isError ? (
            <AlertBox
              textAlign={'left'}
              mb={4}
              status="error"
              title={'Oops!'}
              description={
                'We could not update your Orientation Checklist at this time. Try Again!'
              }
              variant="toast"
              showIcon={AlertTriangle}
            />
          ) : null}
          <Button
            isLoading={updateChecklist.isLoading}
            variant="primary"
            w={'full'}
            onClick={() => {
              updateChecklist.mutate({
                slug: 'add_wearable_size',
                status: 'completed',
              });
            }}
          >
            Done
          </Button>
        </>
      }
    />
  );
}
