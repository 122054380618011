import { Box, Flex, Grid, Icon, Text } from '@chakra-ui/react';

import { ChevronLeftIcon } from '@arena-labs/strive2-ui';

export function LaunchpadActionView({
  onBack,
  hero,
  title,
  textBox,
  footer,
  gradient,
}: {
  title?: string;
  onBack?: () => void;
  hero?: React.ReactNode;
  textBox?: React.ReactNode;
  footer?: React.ReactNode;
  gradient?: string;
}) {
  return (
    <Flex
      w={'full'}
      direction={'column'}
      h={'full'}
      bgGradient={gradient}
      px={'6'}
      pt={'env(safe-area-inset-top)'}
      color={'neutral.white'}
      overflow={'auto'}
    >
      <Grid
        templateColumns={'40px 1fr 40px'}
        textAlign={'center'}
        alignItems={'center'}
        color={'logo'}
        pb={3}
        flexShrink={0}
      >
        {onBack && (
          <Flex gridColumn={1} as={'button'} onClick={onBack} py={4}>
            <Icon as={ChevronLeftIcon} boxSize={4} color={'neutral.800'} />
          </Flex>
        )}
        {title && (
          <Text color={'neutral.800'} w={'full'} textStyle={'copy_bold'}>
            {title}
          </Text>
        )}
      </Grid>

      <Flex
        flex={1}
        w={'full'}
        direction={'column'}
        gap={10}
        align={'center'}
        textAlign={'center'}
        h={'full'}
      >
        {hero && (
          <Box mt={'80px'} w={'full'}>
            {hero}
          </Box>
        )}
        {textBox && <Box mb={'auto'}>{textBox}</Box>}
        {footer && (
          <Flex
            mt={'auto'}
            w={'full'}
            pb={'calc(env(safe-area-inset-bottom) + 24px)'}
            direction={'column'}
          >
            {footer}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
