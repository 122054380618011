import {
  Button,
  Flex,
  Icon,
  RadioProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon, RadioButton } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../layout/app-banner';

export function SelectDeliveryOutcome({
  error,
  setDeliveryOutcome,
  onClose,
  canProceed,
  wearableProvider,
}: {
  error?: React.ReactNode | undefined;
  setDeliveryOutcome: (
    deliveryOutcome: 'ConnectWearable' | 'Download' | 'Wait',
  ) => void;
  onClose: () => void;
  canProceed: boolean;
  wearableProvider: 'striveware' | 'whoop';
}) {
  const { getRadioProps } = useRadioGroup({
    name: 'delivery_outcome',
    onChange: (nextValue: string) => {
      nextValue.match('Yes, completed set up with Oura')
        ? setDeliveryOutcome('ConnectWearable')
        : nextValue.match('Yes, not yet downloaded Oura app')
        ? setDeliveryOutcome('Download')
        : setDeliveryOutcome('Wait');
    },
  });

  const { getRadioProps: getStrivewareRadioProps } = useRadioGroup({
    name: 'striveware_delivery_outcome',
    onChange: (nextValue: string) => {
      nextValue.match('Yes')
        ? setDeliveryOutcome('ConnectWearable')
        : setDeliveryOutcome('Wait');
    },
  });

  const strivewareOptions = (
    <Flex direction={'column'} gap={3}>
      <RadioButton
        variant={'secondary'}
        w={'full'}
        {...(getStrivewareRadioProps({
          value: 'Yes',
        }) as RadioProps)}
      >
        Yes
      </RadioButton>
      <RadioButton
        variant={'secondary'}
        {...(getStrivewareRadioProps({
          value: 'No',
        }) as RadioProps)}
        w={'full'}
      >
        Not Yet
      </RadioButton>
    </Flex>
  );

  const ouraOptions = (
    <Flex direction={'column'} gap={3}>
      <RadioButton
        variant={'secondary'}
        w={'full'}
        {...(getRadioProps({
          value: 'Yes, completed set up with Oura',
        }) as RadioProps)}
      >
        Yes, completed set up with Oura.
      </RadioButton>
      <RadioButton
        variant={'secondary'}
        {...(getRadioProps({
          value: 'Yes, not yet downloaded Oura app',
        }) as RadioProps)}
        w={'full'}
      >
        Yes, not yet downloaded Oura app.
      </RadioButton>
      <RadioButton
        variant={'secondary'}
        {...(getRadioProps({ value: 'Not Yet' }) as RadioProps)}
        w={'full'}
      >
        Not Yet
      </RadioButton>
    </Flex>
  );
  const swiper = useSwiper();

  return (
    <Flex
      direction={'column'}
      w={'100vw'}
      h="full"
      pt={'env(safe-area-inset-top)'}
    >
      <AppBanner shadow={'none'} color={'logo'}>
        <Button onClick={() => onClose()} variant={'unstyled'} size={'xs'}>
          <Icon as={ChevronLeftIcon} boxSize={4} />
        </Button>

        <Text textStyle={'h3'} gridColumn={2}>
          Ring Delivery
        </Text>
      </AppBanner>
      <Flex direction={'column'} h={'full'} px={6} mt={'20%'} gap={10}>
        <Text textStyle={'h2'}>
          Have you received your{' '}
          {wearableProvider === 'striveware' ? 'StriveWare' : 'Oura'} in the
          mail?
        </Text>
        {wearableProvider === 'striveware' ? strivewareOptions : ouraOptions}
        <Flex
          direction={'column'}
          gap={4}
          mt={'auto'}
          mb={'calc(env(safe-area-inset-bottom) + 24px)'}
        >
          {error && error}

          <Button
            variant={'primary'}
            w={'full'}
            onClick={() => swiper.slideNext()}
            disabled={!canProceed}
            pointerEvents={!canProceed ? 'none' : 'inherit'}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
