import { Button, ButtonProps, Circle, Icon } from '@chakra-ui/react';

import { HorizontalBars } from '@arena-labs/strive2-ui';

import { useZendeskUnreadMessages } from '../../../hooks/use-zendesk-unread-messages';
import { useMenu } from './use-menu';

export function MenuButton({ ...props }: ButtonProps) {
  const appMenu = useMenu();
  const unread = useZendeskUnreadMessages();

  return (
    <Button
      variant={'unstyled'}
      size={'xs'}
      onClick={() => appMenu.onOpen()}
      position="relative"
      {...props}
    >
      <Icon as={HorizontalBars} boxSize={6} />
      {typeof unread === 'number' && unread > 0 && (
        <Circle
          size="12px"
          bg="red.500"
          position="absolute"
          top="0"
          right="0"
        />
      )}
    </Button>
  );
}
