import { useEffect } from 'react';
import { Button, chakra, Flex, Text, useCallbackRef } from '@chakra-ui/react';
import { captureException } from '@sentry/nextjs';
import { useQueryClient } from '@tanstack/react-query';

import {
  AlertBox,
  AlertTriangle,
  useAppStateChange,
} from '@arena-labs/strive2-ui';
import { $API, useUserProfile } from '@strive/api';

type EmailCodeEntryProps = { onClose: () => void; isOpen: boolean };

export default function EmailCodeEntry({
  isOpen,
  onClose,
}: EmailCodeEntryProps) {
  const { data: user } = useUserProfile();
  const queryClient = useQueryClient();

  const sendEmail = $API.useEmailVerification(
    {},
    {
      onError: (e) =>
        captureException(e, {
          data: 'Verification Email Failed To Send Email, Email Code entry @ Orientation',
        }),
    },
  );

  const handleSend = useCallbackRef(() => {
    sendEmail.mutate({ email: user?.email ?? '' });
  });

  useEffect(() => {
    if (isOpen && sendEmail.isIdle) {
      handleSend();
    }
  }, [isOpen, sendEmail.isIdle, handleSend]);

  useAppStateChange((isActive) => {
    if (isActive && isOpen) queryClient.invalidateQueries();
  });

  return (
    <Flex direction={'column'} h={'full'} pt={10} px={6}>
      <Text textStyle={'h2'}>Check Your Email</Text>
      <Text textStyle={'copy'} color={'neutral.400'}>
        To confirm your work email address, please open the email we sent to{' '}
        <chakra.span color={'logo'}>{user?.email ?? 'you'}</chakra.span> and
        click the link!
      </Text>
      {sendEmail.isError && (
        <AlertBox
          mt={'4px'}
          status={'error'}
          description={
            <>
              <Text textStyle={'copy_small'}>
                An error occurred and the email was not sent.
              </Text>
            </>
          }
          title={'Oops!'}
          variant="toast"
          showIcon={AlertTriangle}
        />
      )}

      <Button
        isLoading={sendEmail.isLoading}
        mt={6}
        mr={'auto'}
        variant="underline-link"
        color="strive.primary"
        onClick={handleSend}
        alignSelf="center"
        textStyle={'copy_small'}
        p={0}
      >
        Resend Email
      </Button>

      <Button
        mt={'auto'}
        variant={'primary'}
        w={'full'}
        type="submit"
        onClick={() => onClose()}
      >
        Continue
      </Button>
    </Flex>
  );
}
