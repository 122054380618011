import { Modal, ModalBody, ModalContent } from '@chakra-ui/react';

import { useModalDisclosure } from '@arena-labs/strive2-ui';

import { LaunchpadConnectWearable } from '../launchpad-connect-wearable';

AuthorizeWearableModal.useDisclosure = function () {
  return useModalDisclosure('Authorize Wearable Data Access for Strive');
};

export function AuthorizeWearableModal({
  isOpen,
  onClose,
  wearableProvider,
}: {
  isOpen: boolean;
  onClose: () => void;
  wearableProvider: 'striveware' | 'whoop';
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalContent m={0} bgGradient={'none'}>
        <ModalBody m={0} p={0} bgGradient={'none'}>
          <LaunchpadConnectWearable
            wearableProvider={wearableProvider}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
