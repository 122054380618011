import { Button, Flex, Icon, Image, Link } from '@chakra-ui/react';

import {
  AlertBox,
  AlertTriangle,
  ArrowDown,
  useAppStateChange,
  useGradient,
} from '@arena-labs/strive2-ui';
import { queryClient, useUserProfile } from '@strive/api';

import { WelcomeLogoAnimation } from '../auth/welcome-logo-animation';
import { LaunchpadActionText } from './launchpad-action-text';
import { LaunchpadActionView } from './launchpad-action-view';
import { useUpdateLaunchpadChecklist } from './use-update-launchpad-checklist';

type LaunchpadAuthorizeDataSyncProps = {
  onClose: () => void;
  onBack?: () => void;
};

export function LaunchpadAuthorizeDataSync({
  onClose,
  onBack = onClose,
}: LaunchpadAuthorizeDataSyncProps) {
  const { data: user, invalidate } = useUserProfile();

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });

  const statusIsValid = user?.profile?.wearable[0]?.status === 'valid';
  const gradient = useGradient('background');
  const authURL = user?.profile?.wearable[0]?.url;

  useAppStateChange((isActive) => {
    if (isActive) {
      invalidate();
      queryClient.invalidateQueries();
    }
  });

  const handleDone = () => {
    updateChecklist.mutate({
      slug: 'add_wearable_size',
      status: 'completed',
    });
    updateChecklist
      .mutateAsync({
        slug: 'sync_wearable',
        status: 'completed',
      })
      .then(() => {
        onClose();
      });
  };

  const actionViewProps = statusIsValid
    ? {
        hero: (
          <Image
            mx={'auto'}
            src={'./images/launchpad/check-blue-bubble.webp'}
            alt={'You are now synced!'}
            w={'240px'}
          />
        ),
        textBox: (
          <LaunchpadActionText
            boldText="Data Authorization Successful!"
            bodyText={`Remember to refresh the WHOOP app daily to ensure your biometric data is up-to-date.`}
          />
        ),
        footer: (
          <>
            {updateChecklist.isError && (
              <AlertBox
                mb={4}
                variant="toast"
                status={'error'}
                title={'Oops! An Error Occurred'}
                description={
                  'We were unable to update your checklist, please try again or contact Strive Support.'
                }
                showIcon={AlertTriangle}
              />
            )}
            <Button
              isLoading={updateChecklist.isLoading || updateChecklist.isSuccess}
              w={'full'}
              onClick={handleDone}
              variant={'primary'}
            >
              Done
            </Button>
          </>
        ),
      }
    : {
        hero: (
          <Flex direction={'column'} align={'center'}>
            <Flex gap={4} alignItems={'center'} mb={8}>
              <WelcomeLogoAnimation willAnimate={false} />
            </Flex>
            <Image
              src={'./images/launchpad/data-transfer.png'}
              alt="Sync Data"
              w={10}
            />
            <Image
              mt={8}
              src={'./images/whoop/whoop-logo.png'}
              w={'250px'}
              h={'auto'}
              alt={'Whoop'}
            />
          </Flex>
        ),
        textBox: (
          <LaunchpadActionText
            boldText={`Let's authorize your WHOOP data with Strive.`}
            bodyText={`Click the link below to authorize data access and start your Strive journey.`}
          />
        ),
        footer: authURL && (
          <Link
            variant={'buttonPrimary'}
            w={'full'}
            href={authURL}
            target={'_blank'}
          >
            Authorize Data via WHOOP{' '}
            <Icon
              as={ArrowDown}
              boxSize={4}
              ml={1}
              transform={'rotate(225deg)'}
            />
          </Link>
        ),
      };

  return (
    <LaunchpadActionView
      title={'Authorize Data'}
      {...actionViewProps}
      onBack={onBack}
      gradient={gradient}
    />
  );
}
