import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';

import { CloseIcon } from '@arena-labs/strive2-ui';
import { Coach } from '@strive/api';

type CoachDetailProps = {
  coach: Coach | undefined;
  onClose: () => void;
};

export function CoachDetail({ coach, onClose }: CoachDetailProps) {
  return (
    <Flex direction="column" h="full" bg="white" overflow="hidden" pb={6}>
      <Flex direction="column" align="center" position="relative">
        <Box h="175px" w="full" position="relative" overflow="hidden" mb={0}>
          <Image
            src={'/images/launchpad/coach-detail-bg.jpg'}
            alt={coach?.first_name ?? ''}
            width="full"
            height="250px"
            objectFit="cover"
          />
          <Center position="absolute" bottom="-0" left="0" right="0" zIndex={3}>
            <Avatar src={coach?.headshot_url ?? ''} size="2xl" />
          </Center>
          <Flex
            justify="flex-end"
            px={2}
            position="absolute"
            top={4}
            right={4}
            zIndex={2}
            bg={'#BEBEBE33'}
            borderRadius={'full'}
            py={2}
          >
            <Icon
              as={CloseIcon}
              boxSize={8}
              color="white"
              onClick={onClose}
              cursor="pointer"
            />
          </Flex>

          {/* White half-circle overlay */}
          <Box
            position="absolute"
            bottom="-50%"
            left="-5%"
            right="-5%"
            height="100%"
            bg="white"
            borderTopRadius="50%"
            zIndex={1}
          />
        </Box>

        <Flex direction="column" px={4} w="full" mt={0}>
          <Text textStyle="h3" textAlign="center" my={4}>
            {coach?.first_name}
          </Text>

          <Text
            textAlign="center"
            textStyle="copy_small"
            color="gray.700"
            mb={4}
          >
            {coach?.bio}
          </Text>
        </Flex>

        <Flex direction="column" px={4} mb={4} w="full">
          <Text textStyle="copy_bold" mb={4}>
            Coach Info
          </Text>
          <Grid templateColumns="auto 1fr" gap={4} textStyle="copy_small">
            <Text color="neutral.500">Background</Text>
            <Text w={'full'} textAlign={'right'}>
              {coach?.background?.join(', ') ?? ''}
            </Text>

            <Text color="neutral.500">Personal Life</Text>
            <Text w={'full'} textAlign={'right'}>
              {coach?.personal_life?.join(', ') ?? ''}
            </Text>

            <Text color="neutral.500">Specialization</Text>
            <Text w={'full'} textAlign={'right'}>
              {coach?.specialization?.join(', ') ?? ''}
            </Text>

            <Divider />
            <Divider ml={-4} />
            <Text color="neutral.500">Time Zone</Text>
            <Text w={'full'} textAlign={'right'}>
              {coach?.timezone ?? ''}
            </Text>

            <Text color="neutral.500">Regular Availability</Text>
            <Text w={'full'} textAlign={'right'}>
              {coach?.availability?.join(', ') ?? ''}
            </Text>
          </Grid>
        </Flex>

        <Flex direction="column" px={4} w="full">
          <Text textStyle="copy_bold" mb={4}>
            Coaching Style
          </Text>
          <Flex gap={2} wrap="wrap" pb={2}>
            {coach?.coaching_style?.map((style: string) => (
              <Button
                key={style}
                size="sm"
                bg="information.50"
                color="primary.800"
                borderRadius="sm"
                textStyle="button"
              >
                {style}
              </Button>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
