import {
  BoxProps,
  Button,
  Divider,
  Flex,
  Text,
  VStack,
} from '@chakra-ui/react';

import { StriveApiResponse } from '@strive/api';

export function ShippingInformation({
  onEdit,
  address,
}: {
  onEdit: () => void;
  address: StriveApiResponse<'setWearableOrder'>['address'];
}) {
  return (
    <>
      <Flex direction={'row'} justify={'space-between'} mb={3}>
        <Text textStyle={'copy_bold'}>Shipping Information</Text>
        <Button
          variant={'link'}
          color={'primary.500'}
          textStyle={'copy_small'}
          onClick={onEdit}
        >
          <u>
            {' '}
            <Text textStyle={'copy_small'}>Edit</Text>
          </u>
        </Button>
      </Flex>
      <Flex
        direction={'column'}
        bg={'neutral.50'}
        rounded={'md'}
        p={3}
        gap={4}
        mb={10}
        border={'1px solid'}
        borderColor={'neutral.400'}
      >
        <ShippingInformationEntry
          label={'Full Name'}
          entryLines={[`${address?.full_name}`]}
        />
        <ShippingInformationEntry
          label={'Shipping Email'}
          entryLines={[`${address?.shipping_email}`]}
        />

        <Divider />
        <ShippingInformationEntry
          label={'Shipping Address'}
          entryLines={[
            `${address?.company ? address.company : ''}`,
            `${address?.address} ${
              address?.apartment ? address.apartment : ''
            }`,
            `${address?.city}, ${address?.state} ${address?.zip_code}`,
            `${address?.country}`,
          ]}
        />
      </Flex>
    </>
  );
}

export function ShippingInformationEntry({
  label,
  entryLines,
  ...props
}: {
  label: string;
  entryLines: string[];
} & BoxProps) {
  return (
    <VStack spacing={1} align={'left'} {...props}>
      <Text textStyle={'copy_extra_small'} color={'neutral.600'}>
        {label}
      </Text>
      {entryLines.map((line, idx) => (
        <Text textStyle={'copy'} key={idx} color={'neutral.700'}>
          {line}
        </Text>
      ))}
    </VStack>
  );
}
