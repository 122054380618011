import { Button, Flex, Icon, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon, CloseIcon } from '@arena-labs/strive2-ui';

type HeaderWithSwiperProps = {
  onClose: () => void;
  currentSlide: number;
};

export function HeaderWithSwiper({
  onClose,
  currentSlide,
}: HeaderWithSwiperProps) {
  const swiper = useSwiper();

  return (
    <Flex position="absolute" top={0} left={0} right={0} zIndex={1}>
      <Flex px={4} py={4} justify="space-between" align="center" w="full">
        <Flex gap={3} align="center" w="full">
          <Button
            pointerEvents={currentSlide > 0 ? 'auto' : 'none'}
            opacity={currentSlide > 0 ? 1 : 0}
            onClick={() => swiper?.slidePrev()}
            p={0}
            size="xs"
            variant="unstyled"
          >
            <Icon as={ChevronLeftIcon} boxSize="24px" />
          </Button>

          <Text w="full" textStyle="copy" textAlign="center">
            Select My Coach
          </Text>
        </Flex>
        <Icon as={CloseIcon} boxSize={8} onClick={onClose} />
      </Flex>
    </Flex>
  );
}
