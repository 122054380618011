import { Button, Flex, Image, Text, VStack } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { AlertBox, AlertTriangle, useGradient } from '@arena-labs/strive2-ui';
import { WearableProviders } from '@strive/api';

import { LaunchpadActionText } from '../launchpad-action-text';
import { LaunchpadActionView } from '../launchpad-action-view';
import { useUpdateLaunchpadChecklist } from '../use-update-launchpad-checklist';

export function ShipmentConfirmed({
  onClose,
  wearableProvider,
  selection,
}: {
  onClose: () => void;
  wearableProvider: WearableProviders;
  selection: 'Matte Black' | 'Rose Gold';
}) {
  const swiper = useSwiper();

  const bgGradient = useGradient('background');

  const updateChecklist = useUpdateLaunchpadChecklist({ onSuccess: onClose });

  const strivewareHero = (
    <VStack spacing={10}>
      <Text textStyle={'h1'} color={'neutral.800'}>
        StriveWare
      </Text>

      <Image
        src={
          selection === 'Rose Gold'
            ? './images/striveware/rose-gold-ring-no-light-left.webp'
            : './images/striveware/black-ring-grey-light-left.webp'
        }
        alt={'Wearable Device'}
        maxW={'136px'}
        mb={8}
        mx={'auto'}
      />
    </VStack>
  );

  const ouraHero = (
    <Image
      mb={10}
      src={'./images/oura/oura-ring.webp'}
      alt={'Oura Ring Sizing Kit'}
      w={'240px'}
      mx={'auto'}
    />
  );
  return (
    <LaunchpadActionView
      gradient={bgGradient}
      onBack={() => swiper.slidePrev()}
      hero={wearableProvider === 'striveware' ? strivewareHero : ouraHero}
      textBox={
        <LaunchpadActionText
          boldText={'Ring Shipment Confirmed!'}
          bodyText={`Get Excited! Your ${
            wearableProvider === 'striveware' ? 'StriveWare' : 'Whoop'
          } is on its way. Anticipate delivery in 5-7 days. Get ready for a personalized journey with Strive!`}
        />
      }
      footer={
        <Flex direction={'column'} gap={6} w={'full'} align={'center'}>
          {updateChecklist.isError ? (
            <AlertBox
              textAlign={'left'}
              mb={4}
              status="error"
              title={'Oops!'}
              description={
                'We could not update your Orientation Checklist at this time. Try Again!'
              }
              variant="toast"
              showIcon={AlertTriangle}
            />
          ) : null}
          <Button
            isLoading={updateChecklist.isLoading}
            variant="primary"
            w={'full'}
            onClick={() => {
              updateChecklist.mutate({
                slug: 'add_wearable_size',
                status: 'completed',
              });
            }}
          >
            Done
          </Button>
        </Flex>
      }
    />
  );
}
