import { Button, Icon, Text } from '@chakra-ui/react';
import { useSwiper } from 'swiper/react';

import { ChevronLeftIcon } from '@arena-labs/strive2-ui';

import { AppBanner } from '../../../layout/app-banner';

interface OrderBannerProps {
  onClose: () => void;
  title?: string;
}

export const OrderBanner = ({
  onClose,
  title = 'Submit WHOOP Order',
}: OrderBannerProps) => {
  const swiper = useSwiper();

  const handleClose = () => {
    if (swiper.activeIndex === 0) {
      onClose();
    } else {
      swiper.slidePrev();
    }
  };
  return (
    <AppBanner shadow={'none'} color={'neutral.800'}>
      <Button onClick={() => handleClose()} variant={'unstyled'} size={'xs'}>
        <Icon as={ChevronLeftIcon} boxSize={4} mt={1} />
      </Button>
      <Text textStyle={'copy_bold'} gridColumn={2}>
        {title}
      </Text>
    </AppBanner>
  );
};
